import React, { forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../Rebass';
import { AnimatePresence, motion } from '../Motion';
import { Heading, Button, Text } from '../../';
import VideoModal from '../VideoModal';
import { ProgressContainer, ProgressLine } from '../Hero';
import Pattern, { LAYOUT_TYPES } from '../Pattern';
import useHeroParallax from '../../hooks/useHeroParallax';
import theme from '../../theme';
import { Translation } from 'react-i18next';
const HeroContainer = styled(Box) `
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;
const BackgroundContainer = styled(Box) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const ImagePreview = motion.custom(styled(Box) `
  width: 100%;
  height: 100%;
  z-index: 2;
  will-change: transform;
`);
const HeroOverlay = styled(Box) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;
const HeroOverlayInner = styled(Box) `
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
const HeadlineContainer = styled(Box) `
  position: absolute;
  max-width: 80%;
  ${(props) => props.heroType === 'SPVC'
    ? `bottom: 10%;
  left: 10%;
  @media (min-width: ${theme.breakpoints[1]}) {
    max-width: 40%;
  }`
    : `top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);`}
`;
const HeroOverlayBottom = styled(Flex) `
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const StyledVideo = motion.custom(styled.video `
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
`);
const HeroVideo = forwardRef((props, ref) => {
    const { headline, subtitle, fullUrl, previewUrl, renderThumbnail, color = 'textWhite', showPattern, patternProps, colorScheme, captionSx, headlineSx, heroType, buttonText, } = props;
    const [isPreviewBuffered, setIsPreviewBuffered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { headlineStyle, backgroundStyle, captionStyle } = useHeroParallax();
    const playButton = (React.createElement(motion.div, { style: captionStyle },
        React.createElement(Button, { variant: "outline", colorScheme: "baseWhite", icon: "play", onClick: () => setIsModalOpen(true), size: "small" }, buttonText ? (buttonText) : (React.createElement(Translation, null, (t) => t('hero.play'))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(VideoModal, { isOpen: isModalOpen, toggleIsOpen: () => setIsModalOpen(!isModalOpen), videoUrl: fullUrl }),
        React.createElement(HeroContainer, { ref: ref },
            React.createElement(BackgroundContainer, null,
                React.createElement(AnimatePresence, { initial: false }, (isModalOpen || !isPreviewBuffered) && (React.createElement(ImagePreview, { "data-testid": "preview-image", animate: { opacity: 1 }, exit: { opacity: 0 }, initial: { opacity: 1 }, 
                    // @ts-ignore
                    style: backgroundStyle }, renderThumbnail()))),
                React.createElement(StyledVideo, { autoPlay: true, loop: true, muted: true, controls: false, playsInline: true, onCanPlay: () => setIsPreviewBuffered(true), 
                    // @ts-ignore
                    style: backgroundStyle },
                    React.createElement("source", { src: previewUrl, type: "video/mp4" }))),
            React.createElement(HeroOverlay, null,
                React.createElement(HeroOverlayInner, null,
                    React.createElement(HeadlineContainer, { heroType: heroType },
                        React.createElement(motion.div, { style: {
                                ...headlineStyle,
                                willChange: 'transform, opacity, letter-spacing',
                            } },
                            React.createElement(Heading, { as: "h1", size: "hero", color: color, sx: { ...headlineSx } }, headline))),
                    React.createElement(HeroOverlayBottom, { p: [4] },
                        React.createElement(Box, { sx: { flex: ['1 0 20%', '1 0 20%', '1 0 33%'] } }, fullUrl && (React.createElement(Box, { sx: { display: ['none', 'none', 'block', 'block'] } }, playButton))),
                        React.createElement(Flex, { flexDirection: "column", alignItems: "center", justifyContent: "flex-end", textAlign: "center", width: [4 / 12, 4 / 12, 'auto'], sx: { flex: ['1 0 60%', '1 0 60%', '1 0 33%'] } },
                            React.createElement(motion.div, { style: captionStyle },
                                React.createElement(Text, { size: "caption", color: color, textAlign: "center", fontFamily: 'variable', sx: { ...captionSx } }, subtitle)),
                            fullUrl && (React.createElement(Box, { sx: { display: ['block', 'block', 'none', 'none'] }, mt: [1] }, playButton))),
                        React.createElement(Box, { sx: { flex: ['1 0 20%', '1 0 20%', '1 0 33%'] } })),
                    React.createElement(ProgressContainer, null,
                        React.createElement(ProgressLine, { color: colorScheme })),
                    showPattern && (React.createElement(Pattern, Object.assign({ layoutType: LAYOUT_TYPES.HERO }, patternProps))))))));
});
export default HeroVideo;
