import { wrapRootElement as wrap } from "./root-wrapper"
import { wrapPageElement as wrapPage } from "./page-wrapper"
export const wrapRootElement = wrap
export const wrapPageElement = wrapPage

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.state && (location.state.fromModal || location.state.isModal)) {
    return false
  }

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 0)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 0)
  }
  return false
}

export const onClientEntry = async () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }
}
